import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import Logo from "../Assets/Images/logo.png";
import CallIcon from "../Assets/Images/call-icon.png";
import WhatsappIcon from "../Assets/Images/whatsapp-icon.png";
import { axiosHeader, getRequest } from "../Helpers/APIHelper";
import { config } from "../Helpers/Constants";
import { Link } from "react-router-dom";
import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import FormatPhoneNumber from "../Helpers/FormatPhoneNumber";
import { FaPhoneVolume } from "react-icons/fa6";

const NavBar = ({ SeoTitle, SeoDesc, profileInfo, isLoading }) => {
  const demoDomain = config.DEMO_DOMAIN;
  const domainName = window.location.hostname;

  const [scroll, setScroll] = useState(false);
  // console.log(domainName, demoDomain, domainName === demoDomain, "Domain");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    if (localStorage.getItem("token")) {
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cookieconsent.popupsmart.com/src/js/popper.js";
    script.async = true;
    script.onload = () => {
      window.cookieconsent.run({
        notice_banner_type: "simple",
        consent_type: "express",
        palette: "light",
        language: "en",
        page_load_consent_levels: ["strictly-necessary"],
        notice_banner_reject_button_hide: false,
        preferences_center_close_button_hide: false,
        page_refresh_confirmation_buttons: false,
        website_name: profileInfo?.site_url,
        website_privacy_policy_url: `${profileInfo?.site_url}/privacy-policy`,
      });
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* <title>{SeoTitle}</title>
        <meta name="description" content={SeoDesc} />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="60c144cb-3d6c-4b9f-aaed-8033f472cfbe"
          data-blockingmode="auto"
          type="text/javascript"
        ></script> */}
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="60c144cb-3d6c-4b9f-aaed-8033f472cfbe"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
      </Helmet>
      <Navbar className={scroll ? "navbar__wrapper fixed" : "navbar__wrapper"}>
        {domainName === demoDomain ? (
          <div
            className="demo-tag"
            style={{
              background: "#e17d00",
              position: "relative",
              fontSize: "22px",
              fontWeight: "600",
              top: "-15px",
              color: "#fff",
              padding: "20px 0",
              textTransform: "uppercase",
              textAlign: "center",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            30-Day Free Trial. {""}
            <a
              rel="noreferrer"
              className="signup-btn"
              style={{ color: "#fff" }}
              // href="https://buy.stripe.com/6oE2azdqa8QxdG0eUX"
              href="https://accounts.urride.online/register/"
              target="_blank"
            >
              Sign Up
            </a>
          </div>
        ) : (
          ""
        )}
        <Container className="header-wrapper">
          <div className="head">
            <Link className="logo-title" to="/">
              {!isLoading && (
                <img
                  src={
                    !profileInfo?.business_logo_path
                      ? Logo
                      : profileInfo?.business_logo_path
                  }
                  alt="Logo"
                  style={{ height: "30px" }}
                />
              )}{" "}
              <span> {profileInfo?.business_name ?? "Your Business Name"}</span>
            </Link>
            <Navbar.Toggle />

            <div className="cta-button">
              <div className="call-link">
                <Link to={"tel:" + profileInfo?.mobile ?? ""} target="_blank">
                  {/* <img
                    style={{ objectFit: "contain" }}
                    src={CallIcon}
                    alt="Logo"
                  /> */}
                  <div style={{ width: "max-content" }}>
                    <FaPhoneVolume size={30} color="#000" />
                  </div>
                </Link>
              </div>
              <div className="whatsapp-logo">
                <Link
                  to={
                    "https://api.whatsapp.com/send?phone=" +
                      profileInfo?.whatsapp_number ?? ""
                  }
                  target="_blank"
                >
                  <img
                    style={{ objectFit: "contain" }}
                    src={WhatsappIcon}
                    alt="Logo"
                  />
                </Link>
              </div>
            </div>
          </div>

          <Navbar.Collapse className="justify-content-end">
            <Nav className="mr-auto">
              <Nav.Link
                style={{ display: "flex", alignContent: "center", gap: "10px" }}
                className="call-link"
                href={"tel:" + profileInfo?.mobile ?? ""}
              >
                {/* <img src={CallIcon} alt="Logo" /> */}
                <div style={{ width: "max-content" }}>
                  <FaPhoneVolume color="#000" />
                </div>

                {profileInfo?.mobile && (
                  <span style={{ color: "#000" }}>
                    {" "}
                    {FormatPhoneNumber(`${profileInfo?.mobile}`)}{" "}
                  </span>
                )}
              </Nav.Link>

              {/* <Nav.Link href="" target="_blank">
                <img src={WhatsappIcon} alt="Logo" />
              </Nav.Link> */}
              <Button className="btn-main" variant="main">
                <a
                  style={{ textDecoration: "none" }}
                  href={window.location.pathname === "/" ? "#get-quote" : "/"}
                >
                  {" "}
                  Get a Quote
                </a>
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
