import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import InnerBannerNext from "../Components/InnerBannerNext";
import Modal from "react-bootstrap/Modal";
import { getRequest, postRequest } from "../Helpers/APIHelper";
import ForSeo from "../Components/seo/ForSeo";
import PaymentPopup from "../Components/modal/PaymentPopup";
import useLocalStorage from "../hooks/useLocalStorage";
import InnerBanner from "../Components/InnerBanner";
import CheckoutForm from "../Components/CheckoutForm";
import StripeCardForm from "../Components/StripeCardForm";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { showToast } from "../Components/ShowToast";

const PaymentConfirmation = ({ title, desc, profileInfo, setting }) => {
  const { removeItem, addItem } = useLocalStorage();
  const stripe = useStripe();
  const elements = useElements();
  const [stripeErrorMessage, setStripeErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [btnDisable, setBtnDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    booking_id: null,
    message: null,
  });

  // -------for-seo-----
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  const currentURL = window.location.pathname;

  useEffect(() => {
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };
  // ----------------------

  const [quote, setQuote] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [fareData, setFareData] = useState([]);
  const [selected_fleet, setSelectedFleet] = useState(null);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const selected_fleet_id = JSON.parse(
      localStorage.getItem("selected_fleet_id")
    );
    var quote = JSON.parse(localStorage.getItem("quote"));
    setQuote(quote);
    var bookingData = JSON.parse(localStorage.getItem("booking_data"));
    setBookingData(bookingData);
    var fareData = JSON.parse(localStorage.getItem("fare_data"));
    setFareData(fareData);
    setFormData({ quote_id: quote?.quote_id });

    const filteredData =
      quote.fleets.filter(
        (item) => item.fleet_category_id === selected_fleet_id
      )[0] ?? null;
    setSelectedFleet(filteredData);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const CARD_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "20px",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding: "10px",
        border: "1px solid #000",
        borderRadius: "4px",
        backgroundColor: "#fff",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
    iconStyle: "solid",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStripeErrorMessage("");
    if (!stripe || !elements) {
      return;
    }

    const dataToSend = new FormData();
    dataToSend?.append("quote_id", formData.quote_id);
    dataToSend?.append("payment_method", formData.payment_method);

    if (formData?.payment_method == "card") {
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);
      if (token) {
        setFormData({ ...formData, card_token: token?.id });
        dataToSend?.append("card_token", token?.id);
      }
      if (error) {
        setStripeErrorMessage(error?.message);
        return;
      }
    }
    setBtnDisable(true);
    postRequest("quote/finish", dataToSend)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          showToast(response?.message, "");
          if (response?.data?.paypal_redirect_link ?? false) {
            window.location.href = response?.data?.paypal_redirect_link;
          } else {
            removeItem("quote");
            removeItem("selected_fleet");
            removeItem("selected_fleet_id");
            addItem("one_way", JSON.stringify(response?.data?.booking_data));
            addItem(
              "two_way",
              JSON.stringify(response?.data?.return_booking_data)
            );
            addItem("thankMsg", JSON.stringify(response?.title));
            // localStorage.setItem("thankYouMsg", response?.title ?? "");
            if (currentURL === "/iframe/booking-form") {
              navigate(
                "/iframe/thank-you?booking_ref=" +
                  (response?.data?.booking_id ?? "")
              );
            } else {
              if (response?.data?.return_booking_id) {
                navigate(
                  `/thank-you?booking_ref=${response?.data?.booking_id}&return_booking_ref=${response?.data?.return_booking_id}`
                );
              } else {
                navigate(
                  "/thank-you?booking_ref=" + (response?.data?.booking_id ?? "")
                );
              }
            }
          }
        } else {
          addItem(
            "thankMsg",
            JSON.stringify(`${response?.title} ${response?.message}`)
          );
          addItem("one_way", JSON.stringify(response?.data?.booking_data));
          addItem(
            "two_way",
            JSON.stringify(response?.data?.return_booking_data)
          );
          setOpen(true);
          setErrors({
            booking_id: response?.data?.booking_id,
            message: response?.message,
          });

          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title="Make Payment"
        description={seo?.description ?? desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={siteURL ?? ""}
      />
      {btnDisable ? <div className="loading"></div> : ""}
      {/* <NavBar SeoTitle={title} SeoDesc={desc} /> */}
      {/* <InnerBannerNext quote={quote} fleet={selected_fleet} /> */}

      <InnerBanner
        title="Make Your Payment "
        quote={quote}
        fleet={selected_fleet}
      />

      <div class="booking-form-wrapper section-gap">
        <Container>
          <Row>
            <Col lg={12}>
              <div style={{ marginTop: "60px" }} className="booking-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <h4
                        style={{
                          fontSize: "20px",
                          fontWeight: "bolder",
                          width: "100%",
                          textAlign: "center",
                        }}
                        className="mt-0"
                      >
                        {" "}
                        Complete Payment Informations
                      </h4>
                      <div className="divider"></div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          borderBottom: "2px dotted #7d7d7d",
                        }}
                      >
                        <p>
                          Full Name:{" "}
                          {capitalizeFirstLetter(bookingData.first_name)}{" "}
                          {capitalizeFirstLetter(bookingData.last_name)}
                        </p>
                        <p>Email : {bookingData.email}</p>
                        <p>Contact Number : {bookingData.phone}</p>
                      </div>
                    </Col>

                    {quote?.lead_passenger_name &&
                      quote.lead_passenger_name !== "" && (
                        <div>
                          <Col lg={12}>
                            <h4>Booking For Someone Else</h4>
                            <p>
                              Lead Passenger Name:{" "}
                              <strong>{bookingData.lead_passenger_name}</strong>
                            </p>
                            <p>
                              Lead Passenger Number:{" "}
                              <strong>
                                {bookingData.lead_passenger_phone}
                              </strong>
                            </p>
                          </Col>
                        </div>
                      )}

                    {bookingData?.lead_passenger_name &&
                      bookingData?.lead_passenger_phone && (
                        <Col lg={6} md={6}>
                          <h4>
                            {" "}
                            <strong> Lead Passenger Info</strong>
                          </h4>
                          <div>
                            <p>
                              Name : {bookingData?.lead_passenger_name ?? 1}
                            </p>
                            <p>
                              Phone : {bookingData?.lead_passenger_phone ?? 0}
                            </p>
                          </div>
                        </Col>
                      )}

                    <Row>
                      <Col lg={6} md={6}>
                        <h4>
                          {" "}
                          <strong> Capacity & Extras</strong>
                        </h4>
                        <div>
                          <p>Passenger : {bookingData?.passenger ?? 1}</p>
                          <p>Luggage : {bookingData?.luggage ?? 0}</p>
                          <p>Child : {bookingData?.baby_seat ?? 0}</p>
                        </div>
                      </Col>
                      {bookingData?.driver_note && (
                        <Col lg={6} md={6}>
                          <h4>
                            {" "}
                            <strong> Note To Driver</strong>
                          </h4>
                          <p>
                            {bookingData?.driver_note !== ""
                              ? bookingData?.driver_note
                              : "-"}
                          </p>
                        </Col>
                      )}
                    </Row>

                    {quote?.is_from_airport === 1 && (
                      <>
                        <Col lg={12}>
                          <h4>
                            {" "}
                            <strong> Flight Information</strong>
                          </h4>
                          <p>Airlines: {bookingData.airlines}</p>
                          <p>Flight Name: {bookingData.flight_name}</p>
                          <p>Flight Number: {bookingData.flight_number}</p>
                        </Col>
                      </>
                    )}

                    {quote?.is_return_airport === 1 && (
                      <div>
                        <Col lg={12}>
                          <h4>
                            {" "}
                            <strong> Return Flight Information</strong>
                          </h4>
                          <p>Airlines: {bookingData.return_airlines}</p>
                          <p>Flight Name: {bookingData.return_flight_name}</p>
                          <p>
                            Flight Number: {bookingData.return_flight_number}
                          </p>
                        </Col>
                      </div>
                    )}

                    <div style={{ borderBottom: "2px dotted #7d7d7d" }}></div>

                    {/* <Col lg={12}>
                      <h4>Payment Method</h4>
                      <p><strong>{capitalizeFirstLetter(bookingData.payment_method)} Payment</strong></p>
                    </Col> */}
                    <Col lg={12}>
                      <h4>
                        {" "}
                        <strong> Fare Info</strong>
                      </h4>
                      <Row>
                        <Col lg={6}>
                          <div>
                            <p style={{ background: "#d9cccc" }}>
                              OneWay Fare Breakdown
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ margin: "0px" }}>Base Fare</p>
                              <p style={{ margin: "0px" }}>
                                {profileInfo?.currency ?? ""}
                                {fareData?.fare ?? 0}{" "}
                              </p>
                            </div>
                            {/* <p style={{ margin: "0px" }}>
                            Base Fare: {profileInfo?.currency ?? ""}
                            {fareData?.fare ?? 0}{" "}
                          </p> */}
                            {fareData?.additional?.charges &&
                            fareData.additional?.charges?.length > 0
                              ? fareData.additional.charges.map(
                                  ({ name, value }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p style={{ margin: "0px" }} key={name}>
                                        {name}
                                      </p>
                                      <p style={{ margin: "0px" }}>
                                        {profileInfo?.currency ?? ""}
                                        {value}
                                      </p>
                                    </div>
                                  )
                                )
                              : ""}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ margin: "0px" }}>Total Fare</p>
                              <p>
                                {profileInfo?.currency ?? ""}
                                {fareData?.total ?? 0}
                              </p>
                            </div>
                          </div>
                        </Col>
                        {quote?.journey_type === "two_way" && (
                          <>
                            <Col lg={6}>
                              <p style={{ background: "#d9cccc" }}>
                                Two Way Fare Breakdown
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p style={{ margin: "0px" }}>Base Fare</p>
                                <p style={{ margin: "0px" }}>
                                  {profileInfo?.currency ?? ""}
                                  {fareData?.return_fare ?? 0}{" "}
                                </p>
                              </div>

                              {fareData?.return_additional?.charges &&
                              fareData.return_additional?.charges?.length > 0
                                ? fareData.return_additional.charges.map(
                                    ({ name, value }) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p style={{ margin: "0px" }} key={name}>
                                          {name}
                                        </p>
                                        <p style={{ margin: "0px" }}>
                                          {profileInfo?.currency ?? ""}
                                          {value}
                                        </p>
                                      </div>
                                    )
                                  )
                                : ""}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p>Total Fare:</p>
                                <p>
                                  {profileInfo?.currency ?? ""}
                                  {fareData?.return_total ?? 0}
                                </p>
                              </div>
                            </Col>
                          </>
                        )}
                        <Col lg={12}>
                          <h4>
                            <strong>
                              Grand Total:{"  "}
                              {"    "}
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "25px",
                                }}
                              >
                                {profileInfo?.currency ?? ""}
                                {fareData?.grand_total ?? 0}
                              </span>
                            </strong>
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <div className="divider"></div>

                    {/* ---payment-options--- */}

                    <Col lg={12}>
                      {/* <h3 className="title mt-0">
                          Complete Payment Information
                        </h3> */}
                      <h4 className="sub-title">
                        Select Your Preferred Payment Option to Complete Payment
                      </h4>
                    </Col>

                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Select
                          name="payment_method"
                          value={formData.payment_method}
                          onChange={handleChange}
                          required
                        >
                          <option selected disabled value="">
                            Select Your Payment Type
                          </option>
                          {setting?.payments_options?.map((method, index) => (
                            <option key={index} value={method}>
                              {capitalizeFirstLetter(method)}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {formData?.payment_method === "card" && (
                      <>
                        {/* <StripeCardForm
                          formData={formData}
                          handlePaySubmit={handleSubmit}
                        /> */}
                        <div>
                          <label>Card Details</label>
                          <div
                            style={{
                              border: "1px solid #8b8b8b",
                              padding: "10px",
                              borderRadius: "7px",
                            }}
                          >
                            <CardElement options={CARD_OPTIONS} />
                          </div>
                          {stripeErrorMessage && (
                            <p
                              style={{
                                color: "red",
                                fontStyle: "italic",
                                fontSize: "14px",
                                marginTop: "5px",
                              }}
                            >
                              * {stripeErrorMessage}
                            </p>
                          )}
                        </div>
                      </>
                    )}
                  </Row>

                  <Col lg="12">
                    <p style={{ marginTop: "20px" }}>
                      By clicking the "Submit" button, you agree to Our{" "}
                      <Link
                        className="text-link"
                        to="/terms-of-services"
                        target="_blank"
                      >
                        Terms of services
                      </Link>{" "}
                      &
                      <Link
                        className="text-link"
                        to="/privacy-policy"
                        target="_blank"
                      >
                        &nbsp; Privacy Policy
                      </Link>
                    </p>
                  </Col>

                  <Col lg={{ span: 4, offset: 4 }} className="pt-4">
                    <button className="custom-btn" variant="main" type="submit">
                      Make Payment
                    </button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
          <PaymentPopup
            errors={errors}
            open={open}
            handleClose={() => setOpen(false)}
          />
        </Container>
      </div>
    </>
  );
};

export default PaymentConfirmation;
